import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
} from '@backstage/core-plugin-api';

// import { shortcutsApiRef } from '@backstage-community/plugin-shortcuts';
// import { CustomShortcutsImpl } from '...';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  // createApiFactory({
  //   api: shortcutsApiRef,
  //   deps: {},
  //   factory: () => new CustomShortcutsImpl(),
  // }),
  ScmAuth.createDefaultApiFactory(),
];
